/*eslint-disable*/
import React, { useState } from 'react';
import Layout from '../../components/chat-channel/Layout';
import Container from '../../components/chat-channel/Container';
import TitleAndMetaTags from '../../components/common/TitleAndHeader';
import { RequestForm } from '../../components/form';
import { categories, videos } from '../../../video-category-data';

const HeaderLeftImg = require('../../assets/images/videos_header.png');
export const cardOne = require('../../assets/images/video_icons/icon_1.png');
export const cardTwo = require('../../assets/images/video_icons/icon_2.png');
export const cardThree = require('../../assets/images/video_icons/icon_3.png');
export const cardFour = require('../../assets/images/video_icons/icon_4.png');
export const cardFive = require('../../assets/images/video_icons/icon_5.png');
export const cardSix = require('../../assets/images/video_icons/icon_6.png');

export const Image = require('../../assets/images/video_icons/dummy_image.png');

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        description="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
        keywords={[
          'IT Helpdesk Chatbot',
          'HR Chatbot',
          'Workativ Integrations',
        ]}
        ogTitle="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        ogDescription="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
      />
      <Container>
        <div className="w-100 float-left videos_wrapper">
          <Layout backgroundColor={'bg_videos'} logoFor="ASSISTANT">
            <section className="assistant_slider trial_mb_80 bg_market_webinar bg_video">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <div className="main-slider-left">
                      <h1>
                        Know more about the innovative capabilities of Workativ
                        Assistant and transform your workplace support
                      </h1>
                      <p>Subscribe now to watch 100+ videos</p>
                      <div className="footer_form">
                        <RequestForm isFooterForm={false} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 bg_video_img">
                    <div className="main-slider-right assistant_right_img beta-slider">
                      <img src={HeaderLeftImg} alt={HeaderLeftImg} />
                    </div>
                  </div>
                </div>
              </div>
            </section>{' '}
            <CategoryVideos />
            <RequestForm isFooterForm={true} />
          </Layout>
        </div>
      </Container>
    </>
  );
}
const iconMap = [
  {
    icon: cardOne,
    key: '1',
  },
  {
    icon: cardTwo,
    key: '2',
  },
  {
    icon: cardThree,
    key: '3',
  },
  {
    icon: cardFour,
    key: '4',
  },
  {
    icon: cardFive,
    key: '5',
  },
  {
    icon: cardSix,
    key: '6',
  },
];

export const categoryMenu = categories
  .filter((category) => category.type.hirarchy === 'main')
  .map((category) => {
    const icon = iconMap.find((icon) => icon.key === category.key);

    const count = videos.filter((video) =>
      video.categories.includes(category.key)
    ).length;

    return {
      ...category,
      icon: icon && icon.icon ? icon.icon : cardOne,
      count: `(${count})`,
    };
  });
const CategoryVideos = (_props) => {
  const [active] = useState(active);
  // const [activeCategory, setActiveCategory] = useState("1");

  const redirectToVideos = (category) => {
    console.log('categories', category);
    window.location.href = `/conversational-ai-platform/chatbot-videos?cat=${category.key}`;
  };
  // /conversational-ai-platform/chatbot-videos
  return (
    <div className="category_wrapper">
      <div className="container">
        <h3>Videos by Category</h3>
        <div className="category_lsiting_href">
          <a className="category_lsiting" href="javascript:void();">
            {categoryMenu.map((list) => (
              <div
                className="category_lsiting_card"
                onClick={() => {
                  redirectToVideos(list);
                }}
              >
                <img src={list.icon} alt="" />
                <h4>{list.menu}</h4>
                <h6>{list.count} Videos</h6>
              </div>
            ))}
          </a>
        </div>
      </div>
    </div>
  );
};
